import type { FacetViewProps as UiFacetViewProps } from '@elastic/react-search-ui-views';
import React, { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import type { FacetValue } from '@elastic/search-ui';
import { classNames } from '../../utilities/styleUtils';

function updateObjectList(selected: string[], selectedObjects: FacetValue[]): [string[], string[]] {
  const objectNames = selectedObjects.map((object) => object.value as string);

  const namesToRemove = objectNames.filter((objectName) => !selected.includes(objectName));
  const namesToAdd = selected.filter((name) => objectNames.includes(name));

  return [namesToAdd, namesToRemove];
}

export default function FacetMultiSelectView({
  label,
  onMoreClick,
  onRemove,
  onChange,
  onSelect,
  options,
  showMore,
  values,
  showSearch,
  onSearch,
}: UiFacetViewProps) {
  const [query, setQuery] = useState('');

  const filteredOptions: FacetValue[] =
    query === ''
      ? options
      : options.filter((option) => {
          if (typeof option.value === 'string') {
            return option.value.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''));
          }
          return false;
        });
  return (
    <div className="">
      <Combobox
        value={values}
        onChange={(onChangeOptions: string[]) => {
          if (onChangeOptions !== undefined) {
            const [namesToAdd, namesToRemove] = updateObjectList(onChangeOptions, options);
            namesToAdd.forEach((name) => {
              onSelect(name);
            });
            namesToRemove.forEach((name) => {
              onRemove(name);
            });
          }
        }}
        multiple
      >
        {({ open }) => (
          <div className="relative mt-1">
            <Combobox.Label>
              <span>
                {label}
                {'  '}
                <span className="text-base font-light text-gray-600">
                  ({values.length}/{options.length})
                </span>
              </span>
            </Combobox.Label>
            <div
              className={classNames(
                open ? 'ring-2 bg-white ring-dataops-light-blue' : 'bg-white ring-1 ring-[#ccc]',
                'relative w-full cursor-default overflow-hidden rounded-lg sm:text-sm text-left',
              )}
            >
              <Combobox.Button className="min-w-[237px] flex items-center">
                <Combobox.Input
                  className="w-52 border-none py-2 pl-3 text-sm leading-5 text-gray-900  focus:outline-none"
                  displayValue={(displayOptions: string[]) =>
                    displayOptions.map((displayOption) => displayOption).join(', ')
                  }
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                />
                <ChevronUpDownIcon className=" w-6 text-gray-400" aria-hidden="false" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-5"
              enterTo="opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0 translate-y-5"
              afterLeave={() => {
                setQuery('');
              }}
            >
              <Combobox.Options
                id="isVisibleElement"
                className="z-20 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                {filteredOptions.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
                ) : (
                  filteredOptions.map((filteredOption) => {
                    if (typeof filteredOption.value === 'string') {
                      const optionValue = filteredOption.value;
                      return (
                        <Combobox.Option
                          key={optionValue}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-dataops-light-blue text-white' : 'text-gray-900'
                            }`
                          }
                          value={optionValue}
                          accessKey="value"
                        >
                          {({ selected, active }) => {
                            return (
                              <div className="flex justify-between">
                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                  {optionValue}
                                </span>
                                <span className={active ? '' : 'text-secondary-text'}>{filteredOption.count}</span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-sky-600'
                                    }`}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </div>
                            );
                          }}
                        </Combobox.Option>
                      );
                    }
                    return null;
                  })
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
}
