import React from 'react';
import { type ISolution } from '../../../reducers/solutionsSlice';
import { ModalFooter } from './ShareModal';

interface ShareDeploymentProps {
  solution?: ISolution;
}

export default function ShareDeployment({ solution }: ShareDeploymentProps) {
  return (
    <div>
      <div className="p-6">
        <div className="flex flex-col space-x-4 cursor-default">
          <label className="flex items-center text-sm leading-6 text-gray-900 font-semibold">Shared with:</label>
          <span>Nobody</span>
        </div>
        <div className="flex flex-row space-x-4 cursor-default">
          <label className="flex items-center text-sm leading-6 text-gray-900 font-semibold">
            Select user to share with:
          </label>
          <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
            <svg className="h-1.5 w-1.5 fill-dataops-dark-red" viewBox="0 0 6 6" aria-hidden="true">
              <circle cx={3} cy={3} r={3} />
            </svg>
            Access: SSC users
          </span>
        </div>
        <div className="flex gap-x-2 my-2">
          <input
            disabled
            placeholder="Select"
            className="block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-black"
          />
        </div>
      </div>
      <ModalFooter title="What am I sharing?">
        <p className="text-sm text-gray-800 max-w-2xl">
          This will share your deployment and is only accessible to the users you specify. It includes both data and
          code, any demos will be run on your configured Snowflake account.
        </p>
        <p className="text-sm text-gray-800 max-w-2xl">Share the deployment with your colleagues for collaboration.</p>
      </ModalFooter>
    </div>
  );
}
