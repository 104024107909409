import * as React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { type IBroadcast } from '../../interfaces/IBroadcast';
import { selectAcknowledgedBroadcastIds, setBroadcastAcknowledged } from '../../reducers/accountSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Markdown from 'react-markdown';
import { MegaphoneIcon } from '@heroicons/react/24/outline';
import rehypeRaw from 'rehype-raw';

interface IBroadcastBannersProps {
  broadcastData: IBroadcast[];
}

export default function BroadcastBanners(props: IBroadcastBannersProps) {
  const dispatch = useAppDispatch();
  // Filter out target_path that starts with `snowflake`.
  const acknowledgedBroadcasts = useAppSelector(selectAcknowledgedBroadcastIds);
  const banners = props.broadcastData
    .filter((broadcast) => broadcast.broadcast_type === 'banner')
    .filter((broadcast) => broadcast.active)
    .filter((broadcast) => broadcast.target_path?.startsWith('/snowflake'))
    .filter((broadcast) => !acknowledgedBroadcasts.includes(broadcast.id))
    .filter((broadcast) => new Date(broadcast.starts_at) <= new Date())
    .filter((broadcast) => new Date(broadcast.ends_at) >= new Date());

  return (
    <div className="divide-y divide-dataops-light-green broadcast banner">
      {banners.map((banner) => (
        <div
          key={banner.id}
          className="relative isolate flex items-center gap-x-6 overflow-hidden bg-dataops-dark-green px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
        >
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          ></div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          ></div>
          <div className="flex flex-row items-center gap-x-4 gap-y-2">
            <MegaphoneIcon className="w-5 min-w-5 text-white" />
            <div className="text-sm leading-6 text-white">
              <Markdown linkTarget="_blank" rehypePlugins={[rehypeRaw]}>
                {banner.message}
              </Markdown>
            </div>
          </div>
          <div className="flex flex-1 justify-end">
            {banner.dismissable && (
              <button
                type="button"
                className="-m-3 p-3 focus-visible:outline-offset-[-4px] hover:bg-green-700 rounded-lg"
                onClick={() => {
                  dispatch(setBroadcastAcknowledged([banner.id, ...acknowledgedBroadcasts]));
                }}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
