import React from 'react';
import { type IDeployment } from '../../reducers/deploymentsSlice';
import { getMainPipeline } from '../../utilities/pipelines';
import { classNames } from '../../utilities/styleUtils';

interface Props {
  deployment: IDeployment;
}

export default function PipelineProgressBar({ deployment }: Props) {
  const currentPipeline = getMainPipeline(deployment.pipelines);

  // Given the pipeline job.status, we can determine the progress of the pipeline
  // The progress is a total of all the jobs with success status divided by the total number of jobs
  const totalJobs = currentPipeline?.jobs.length ?? 0;
  const successfulJobs = currentPipeline?.jobs.filter((job) => job.status === 'SUCCESS').length ?? 0;
  const failedJobs = currentPipeline?.jobs.filter((job) => job.status === 'FAILED').length ?? 0;
  const percent = (successfulJobs / totalJobs) * 100;
  const widthStyle = percent > 0 ? `${percent}%` : '100%';

  return (
    <div
      style={{ width: widthStyle }}
      className={classNames(
        'absolute top-0 left-0 h-[2px]',
        percent > 0 ? 'bg-in-progress' : '',
        percent === 0 ? 'bg-pending' : '',
        percent === 100 ? 'hidden' : '',
        failedJobs > 0 ? 'bg-failed' : '',
      )}
    ></div>
  );
}
