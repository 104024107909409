import React, { useContext, useEffect, useRef, useState } from 'react';
import FormField from '../../controls/FormField';
import { AuthContext, type IAuthContext } from 'react-oauth2-code-pkce';
import { getEnvVariableValue, parseGuid } from '../../../utilities/parsers';
import {
  mutateDeploymentShareUrl,
  mutateDeploymentVariable,
  type IDeployment,
} from '../../../reducers/deploymentsSlice';
import { ArrowTopRightOnSquareIcon, CheckIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { salesForceIdValidation, ValidationLevel } from '../../../utilities/validation';
import { Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Button from '../../controls/Button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ModalFooter } from './ShareModal';
import CloseButton from '../../controls/CloseButton';
import { sendMetrics } from '../../../utilities/analytics';
import { selectUser } from '../../../reducers/accountSlice';
import { type ICurrentUser } from '../../../interfaces/ICurrentUser';

function canGenerateShareUrl(errorMessage: string, unsaved: boolean): boolean {
  /**
   * If the ID is already valid and saved, the user can generate a share URL.
   * If the ID is valid and unsaved, the user can generate a share URL.
   * If the ID is invalid, the user cannot generate a share URL.
   */
  if (errorMessage === '' && unsaved) {
    return true;
  }
  if (errorMessage === '' && !unsaved) {
    return false;
  }
  return false;
}

// #region Share Code With Customer
interface ShareCodeWithCustomerProps {
  projectDeployment?: IDeployment;
  closeModal: () => void;
}

export default function ShareCodeWithCustomer({ projectDeployment, closeModal }: ShareCodeWithCustomerProps) {
  /**
   * If no deployment is provided, the user will need to select one.
   */
  if (projectDeployment === undefined) {
    return <div>No deployment selected</div>;
  } else {
    return <ShareDeploymentCode projectDeployment={projectDeployment} closeModal={closeModal} />;
  }
}

// #region Share Deployment
interface ShareDeploymentProps {
  projectDeployment: IDeployment;
  closeModal: () => void;
}

function ShareDeploymentCode({ projectDeployment, closeModal }: ShareDeploymentProps) {
  const currentUser = useAppSelector(selectUser);
  const salesForceId = getEnvVariableValue(projectDeployment.ciVariables, 'DATAOPS_CATALOG_OPPORTUNITY_ID');
  const shareUrlVar = getEnvVariableValue(projectDeployment.ciVariables, 'DATAOPS_CATALOG_SHARE_URL');
  const [salesForceIdValue, setSalesForceIddValue] = useState<string>(salesForceId);
  const [shareUrl, setShareUrl] = useState<string>(shareUrlVar ?? '');
  const [linkLabel, setLinkLabel] = useState<string>('Getting link...');
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const hasCopiedRef = useRef(false); // Ref to track if the URL has been copied outside of re-renders.

  function isShareUrlAvailable(shareUrl: string): boolean {
    return shareUrl.length > 0;
  }

  function handleIsCopied(value: boolean) {
    hasCopiedRef.current = value;
  }

  async function copyTextToClipboard(value: string) {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(value ?? '');
    } else {
      document.execCommand('copy', true, value);
    }
    if (currentUser === undefined) {
      console.error('No current user found.');
      return;
    }
    sendMetrics({
      event_name: 'dataops-share-service-url-copied',
      event_source: 'frostbyte-deployment-portal',
      properties: {
        opportunity_id: salesForceId,
        operate_project_full_path: projectDeployment.fullPath,
        operate_project_id: projectDeployment.id,
        operate_email: currentUser.emails.nodes[0]?.email,
        operate_user_id: parseGuid(currentUser.id),
      },
    });
  }

  async function checkAndCopyShareUrl(shareUrl: string) {
    if (isShareUrlAvailable(shareUrl) && !hasCopiedRef.current) {
      hasCopiedRef.current = true;
      await copyTextToClipboard(shareUrl);
      setLinkLabel('Link Copied!');
    } else {
      console.log('No share URL available or already copied.');
    }
  }

  useEffect(() => {
    if (isShareUrlAvailable(shareUrl)) {
      checkAndCopyShareUrl(shareUrl).catch((e) => {
        console.error('Error copying share URL', e);
      });
    }
  }, [shareUrl]);

  useEffect(() => {
    setShareUrl(getEnvVariableValue(projectDeployment.ciVariables, 'DATAOPS_CATALOG_SHARE_URL'));
  }, [projectDeployment.ciVariables]);

  return (
    <div>
      {shareUrl.length > 0 && !showSettings ? (
        <ShareDeploymentCopyLink
          setShowSettings={setShowSettings}
          salesForceId={salesForceId}
          linkLabel={linkLabel}
          shareUrl={shareUrl}
          closeModal={closeModal}
        />
      ) : (
        <ShareDeploymentSettings
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          setIsCopied={handleIsCopied}
          projectDeployment={projectDeployment}
          origSalesForceId={salesForceId}
          salesForceIdValue={salesForceIdValue}
          setSalesForceIdValue={setSalesForceIddValue}
          closeModal={closeModal}
        />
      )}
      <ModalFooter title="What am I sharing?">
        <p className="text-sm text-gray-800 max-w-2xl">
          This link is to a copy of your deployment code that is publicly accessible to anyone. Share it with your
          customer after a demo so they can look over the code.
        </p>
      </ModalFooter>
    </div>
  );
}

// #region Share Deployment Copy Link
function ShareDeploymentCopyLink({
  setShowSettings,
  salesForceId,
  linkLabel,
  shareUrl,
  closeModal,
}: {
  setShowSettings: (value: boolean) => void;
  salesForceId: string;
  linkLabel: string;
  shareUrl: string;
  closeModal: () => void;
}) {
  return (
    <div className="relative px-6 py-5 space-y-2">
      <div className="flex flex-row space-x-4 cursor-default">
        <h3 className="flex flex-grow items-center ml-0 text-md text-gray-900 font-semibold">
          {linkLabel} <CheckIcon className="h-6 w-8" color="green" />
        </h3>
        <CloseButton setOpen={closeModal} className="" />
      </div>
      <p className="text-sm text-dataops-dark">Anyone can access this link.</p>
      <div className="flex flex-row space-x-2">
        <p className="text-sm text-dataops-dark/50">Use Case ID:</p>
        <code className="text-sm text-dataops-dark/50">{salesForceId}</code>
        <button
          className="text-sm text-sky-600 hover:underline"
          onClick={(e) => {
            e.preventDefault();
            setShowSettings(true);
          }}
        >
          Change
        </button>
      </div>
      {/* Disabled as its cleaner without. */}
      {/* <a
        href={shareUrl}
        target="_blank"
        rel="noreferrer"
        className="flex flex-row items-center text-sm text-sky-600 hover:underline"
      >
        Open share in new tab <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
      </a> */}
    </div>
  );
}

// #region Share Deployment Settings
function ShareDeploymentSettings({
  showSettings,
  setShowSettings,
  setIsCopied,
  projectDeployment,
  origSalesForceId,
  salesForceIdValue,
  setSalesForceIdValue,
  closeModal,
}: {
  showSettings: boolean;
  setShowSettings: (value: boolean) => void;
  setIsCopied: (value: boolean) => void;
  projectDeployment: IDeployment;
  origSalesForceId: string;
  salesForceIdValue: string;
  setSalesForceIdValue: (value: string) => void;
  closeModal: () => void;
}) {
  const dispatch = useAppDispatch();
  const { token } = useContext<IAuthContext>(AuthContext);
  const currentUser = useAppSelector(selectUser);
  const [salesForceIdErrorMessage, setSalesForceIdErrorMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [canShare, setCanShare] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const salesForceIdUnsaved = salesForceIdValue !== origSalesForceId;
  const hasRequested = useRef(false);

  useEffect(() => {
    // Auto share if Sales Force ID is valid.
    if (
      salesForceIdValidation(salesForceIdValue, ValidationLevel.AllChecks) === '' &&
      !showSettings &&
      !hasRequested.current
    ) {
      hasRequested.current = true;
      generateShareUrl(token, projectDeployment, salesForceIdValue, salesForceIdUnsaved, currentUser).catch((e) => {
        console.error('Error generating share URL', e);
      });
      setShowSettings(false);
    }

    setCanShare(canGenerateShareUrl(salesForceIdErrorMessage, salesForceIdUnsaved));
  }, []);

  useEffect(() => {
    if (projectDeployment.requestShareUrlStatus === 'failed') {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [projectDeployment.requestShareUrlStatus]);

  useEffect(() => {
    setSalesForceIdErrorMessage(salesForceIdValidation(salesForceIdValue, ValidationLevel.AllChecks));
    setCanShare(canGenerateShareUrl(salesForceIdErrorMessage, salesForceIdUnsaved));
  }, [salesForceIdValue]);

  useEffect(() => {
    if (projectDeployment.requestShareUrlStatus === 'loading') {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [projectDeployment.requestShareUrlStatus]);

  async function generateShareUrl(
    token: string,
    deployment: IDeployment,
    salesForceId: string,
    salesForceIdUnsaved: boolean,
    currentUser: ICurrentUser | undefined,
  ) {
    if (currentUser === undefined) {
      console.error('No current user found.');
      return;
    }
    if (salesForceIdUnsaved) {
      await dispatch(
        mutateDeploymentVariable({
          token,
          deployment,
          varKey: 'DATAOPS_CATALOG_OPPORTUNITY_ID',
          varValue: salesForceId,
        }),
      );
      sendMetrics({
        event_name: 'dataops-opportunity-id-updated',
        event_source: 'frostbyte-deployment-portal',
        properties: {
          opportunity_id: salesForceId,
          operate_project_full_path: projectDeployment.fullPath,
          operate_project_id: projectDeployment.id,
          operate_email: currentUser.emails.nodes[0]?.email,
          operate_user_id: parseGuid(currentUser.id),
        },
      });
    }

    await dispatch(mutateDeploymentShareUrl({ token, deployment: projectDeployment }));
    sendMetrics({
      event_name: 'dataops-share-service-url-generated',
      event_source: 'frostbyte-deployment-portal',
      properties: {
        opportunity_id: salesForceId,
        operate_project_full_path: projectDeployment.fullPath,
        operate_project_id: projectDeployment.id,
        operate_email: currentUser.emails.nodes[0]?.email,
        operate_user_id: parseGuid(currentUser.id),
      },
    });
    setShowSettings(false);
    setIsCopied(false);
  }

  return (
    <div className="static">
      {showLoading ? (
        <>
          <h3 className="flex flex-grow items-center ml-0 px-6 py-5 text-md text-gray-900 font-semibold">
            Getting link...
          </h3>
          <div className="w-full h-1 bg-in-progress"></div>
        </>
      ) : (
        <form className="px-6 py-5 space-y-8">
          {showError && (
            <div className="rounded-md bg-dataops-dark-red/10 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-dataops-dark-red" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-dataops-dark-red">Error while creating the share link</h3>
                </div>
              </div>
            </div>
          )}
          <FormField
            label="Use Case ID"
            labelClassName=" mt-4 font-semibold"
            className=""
            value={salesForceIdValue}
            labelExtra={
              <>
                <a
                  href="https://snowforce.lightning.force.com/lightning/"
                  target="_blank"
                  rel="noreferrer"
                  className=" mt-4 text-sm hover:underline text-sky-600 ml-2 flex items-center"
                >
                  Launch Salesforce
                  <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                </a>
                <CloseButton setOpen={closeModal} className="absolute top-5 right-6" />
              </>
            }
            onChange={(e) => {
              setSalesForceIdErrorMessage(salesForceIdValidation(e.target.value, ValidationLevel.AllChecks));
              setSalesForceIdValue(e.target.value);
            }}
            required
          >
            <Transition
              as="div"
              className="space-y-2"
              show={true}
              enter="transition duration-[300ms]"
              enterFrom="transform opacity-0 scale-[0.9]"
              enterTo="transform opacity-100 scale-100"
              leave="transition duration-[300ms] ease-in-out"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-[0.9]"
            >
              {salesForceIdUnsaved && (
                <div className="flex space-x-2">
                  <InformationCircleIcon className="h-5 w-5 text-sky-800" aria-hidden="true" />
                  <p className="text-sm font-medium text-sky-800">Unsaved changes will save when shared.</p>
                </div>
              )}
              <Transition
                as="div"
                className={'space-y-4'}
                show={salesForceIdErrorMessage !== ''}
                enter="transition duration-[300ms]"
                enterFrom="transform opacity-0 scale-[0.9]"
                enterTo="transform opacity-100 scale-100"
                leave="transition duration-[300ms] ease-in-out"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-[0.9]"
              >
                <div className="rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">Validation warning</h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>{salesForceIdErrorMessage}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </Transition>
          </FormField>
          <p className="text-sm text-dataops-dark">Sharing code with a customer requires their Use Case ID.</p>
          <Button
            disabled={!canShare}
            onClick={(e) => {
              e.preventDefault();
              generateShareUrl(token, projectDeployment, salesForceIdValue, salesForceIdUnsaved, currentUser).catch(
                (e) => {
                  console.error('Error generating share URL', e);
                },
              );
            }}
            className=""
          >
            Share
          </Button>
        </form>
      )}
    </div>
  );
}
