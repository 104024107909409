import { FolderOpenIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { BeakerIcon } from '@heroicons/react/20/solid';
import axios, { type AxiosResponse } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import Button from '../controls/Button';
import { classNames } from '../../utilities/styleUtils';
import { getConfig } from '../../config/config-helper';
import ImportColumnLayout from '../layout/ImportColumnLayout';
import TierBadge from '../project/TierBadge';
import { sendMetrics } from '../../utilities/analytics';

import githubCodeCloneHttps from '../../assets/gif/github_code_clone_https.gif';

export default function NewFromGitPath() {
  const [urlUploadValue, setUrlUploadValue] = useState('');
  const [urlImportError, setUrlImportError] = useState('');
  const [fileImportError, setFileImportError] = useState('');
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const navigate = useNavigate();
  const { dataopsCatalogApi2Endpoint } = getConfig();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [urlForFileUpload, setUrlForFileUpload] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [seeHelp, setSeeHelp] = useState(false);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  // const appName = "dataops-live-project-morph-local";

  const validateUrl = (url: string) => {
    const regex = /^(https?:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_.-]+\/[A-Za-z0-9_.-]+\/?$/;
    return regex.test(url);
  };

  const submitImportRequestAndReturnId = async (url: string): Promise<number | undefined> => {
    try {
      const response: AxiosResponse<any, any> = await axios.post(`${dataopsCatalogApi2Endpoint}/import`, {
        repo_path: url,
        oauth_token: token,
      });
      const analyticsData = {
        event_name: 'dataops-import-from-git-repo-submitted',
        event_source: 'frostbyte-deployment-portal',
        properties: {
          url,
        },
      };
      sendMetrics(analyticsData);
      return response.data.id;
    } catch (error) {
      console.error(error);
      setUrlImportError('Failed to submit the URL. Please try again.');
      return undefined;
    }
  };

  const submitFileImportRequest = async (formData: FormData): Promise<number | undefined> => {
    try {
      const response: AxiosResponse<any, any> = await axios.post(`${dataopsCatalogApi2Endpoint}/import/zip`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const analyticsData = {
        event_name: 'dataops-import-from-git-zip-submitted',
        event_source: 'frostbyte-deployment-portal',
        properties: {
          formData,
        },
      };
      sendMetrics(analyticsData);
      return response.data.id;
    } catch (error) {
      console.error(error);
      setFileImportError('Failed to submit the URL. Please try again.');
      return undefined;
    }
  };

  const handleUrlSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (validateUrl(urlUploadValue)) {
      setUrlImportError('');
      // await installGitHubApp();
      const importId = await submitImportRequestAndReturnId(urlUploadValue);
      if (importId !== undefined) {
        navigate(`/new/from-git/import/${importId}`);
      }
      setUrlImportError('');
    } else {
      setUrlImportError('Invalid GitHub repository URL.');
    }
  };

  const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setUrlUploadValue(event.target.value);
    if (urlImportError !== '') setUrlImportError('');
  };

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlForFileUpload(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUploadSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedFile) {
      setFileImportError('Please select a file to upload.');
      return;
    } else {
      setFileImportError('');
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('repo_path', urlForFileUpload);
    formData.append('oauth_token', token);
    const importId = await submitFileImportRequest(formData);
    if (importId !== undefined) {
      navigate(`/new/from-git/import/${importId}`);
    }
  };

  return (
    <ImportColumnLayout>
      <div>
        <span
          className={classNames(
            'text-dataops-light-green',
            'inline-flex rounded-lg p-3 ring-1 ring-dataops-light-green',
          )}
        >
          <GlobeAltIcon aria-hidden="true" className="h-6 w-6" />
        </span>
      </div>
      <h1 className="text-xl">New Solution from Git repository</h1>
      <div className="flex space-x-1">
        <div className="inline-flex items-center px-1 space-x-1 text-sm text-gray-800 rounded-md ring-1 ring-inset cursor-default ring-gray-500/10">
          <BeakerIcon className="w-4 h-4" /> <span>Private Preview</span>
        </div>
        <TierBadge tier={4} />
      </div>
      <div className="space-y-2">
        <div className="w-full p-4 leading-5 space-y-2 rounded-md text-gray-700 bg-dataops-dark-blue/5">
          <h2 className="text-sm">URL Requirements</h2>
          <ul className="list-disc pl-6 text-sm">
            <li>Must be a publicly accessible URL.</li>
            <li>
              Must be formatted so that it can be cloned. For example <code>git clone ...</code>{' '}
              <a
                href="#"
                onClick={() => {
                  setSeeHelp(!seeHelp);
                }}
                className="text-sky-700 hover:underline bg-sky-500/5 rounded-md px-1"
              >
                See {seeHelp && <span>less</span>} help
              </a>
              .
            </li>
          </ul>
        </div>
        <div
          className={classNames(
            'p-4 space-y-8 rounded-md bg-dataops-dark-blue/5 transition-all ease-in-out duration-300',
            seeHelp ? 'py-4 h-full opacity-100' : 'p-0 h-0 opacity-0 scale-0',
          )}
        >
          <div className="space-y-2 ">
            <h2 className="text-md">Example importing from Github</h2>
            <p className="text-sm">Copy the HTTPS clone URL from the repository.</p>
            <img src={githubCodeCloneHttps} alt="GitHub clone HTTPS" className="max-w-sm h-auto rounded-md" />
            <p className="text-sm">To test your repo URL, open a terminal and run the following command:</p>
            <pre className="p-2 text-sm rounded-md bg-slate-700 text-white">
              <code>git clone YOUR_REPO_URL</code>
            </pre>
            <p className="text-sm italic">
              Note: You should not be prompted for credentials, if you are, then the repo may be private.
            </p>
            <p className="text-sm">When this command works, the URL can be used in the input above.</p>
          </div>
        </div>
      </div>
      <div className="space-y-24">
        <div className="space-y-8">
          <div className="flex items-center justify-center">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 text-center max-w-xs text-xl text-gray-400">Enter a URL to a public repo.</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          <form onSubmit={handleUrlSubmit} className="w-full space-y-4">
            <div className="flex flex-row gap-2">
              <div className="h-[33px] relative flex-1 rounded-md bg-dataops-supporting-gray/50">
                <GlobeAltIcon
                  className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <input
                  ref={inputRef}
                  onChange={handleChange}
                  placeholder="Paste in a link to a public repo"
                  className="h-[33px] w-full border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-light-blue/20 focus:outline-4 transition-all ease-in-out"
                  data-testid="repo-url-input"
                  value={urlUploadValue}
                />
              </div>
              <Button type="submit" className="" data-testid="import-button" disabled={urlUploadValue === ''}>
                Import
              </Button>
            </div>
            {urlImportError !== '' && <p>{urlImportError}</p>}
          </form>
        </div>
        <div className="space-y-8">
          <div className="flex items-center justify-center">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 text-center max-w-xs text-xl text-gray-400">
              Or enter a URL to a private repo and upload a ZIP file of contents.
            </span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
          <form onSubmit={handleFileUploadSubmit} className="w-full space-y-4" encType="multipart/form-data">
            <div className="flex flex-row gap-2">
              <div className="relative basis-2/3">
                <GlobeAltIcon
                  className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <input
                  onChange={handleUrlChange}
                  placeholder="Paste in a link to a private repo"
                  className="h-[33px] w-full border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0  focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-light-blue/20 focus:outline-4 transition-all ease-in-out"
                  data-testid="manual-repo-url-input"
                  value={urlForFileUpload}
                />
              </div>
              <div className="relative basis-1/3">
                <FolderOpenIcon
                  className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="h-[33px] border border-gray-200 bg-transparent pl-11 text-gray-900 placeholder:text-gray-500 hover:cursor-pointer hover:bg-dataops-dark-blue/5 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-light-blue/20 focus:outline-4 transition-all ease-in-out
              file:mr-4 file:mt-1 file:py-0.5 file:px-0.5
              file:max-w-xs file:rounded-md file:border-0
              file:text-sm file:bg-white file:text-gray-900
              file:shadow-sm
              file:ring-1
              file:ring-gray-300
              file:hover:cursor-pointer
              hover:file:bg-gray-200
              disabled:file:bg-gray-500
              disabled:hover:file:!bg-gray-500
              disabled:file:text-white
              "
                  data-testid="file-input"
                />
              </div>
              <Button type="submit" className="" data-testid="import-button" disabled={urlForFileUpload === ''}>
                Import
              </Button>
            </div>
            {fileImportError !== '' && <p>{fileImportError}</p>}
            <div className="w-full p-4 leading-5 space-y-2 rounded-md text-gray-700 bg-dataops-dark-blue/5">
              <div className=" text-sm">
                Why a URL? - We use the private URL to link back to the original content in the search results.
              </div>
            </div>
          </form>
        </div>
      </div>
      <Button
        intent={'light'}
        onClick={() => {
          navigate('/new');
        }}
      >
        Back
      </Button>
    </ImportColumnLayout>
  );
}
