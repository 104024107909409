import { XMarkIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { classNames } from '../../utilities/styleUtils';

interface CloseButtonProps {
  setOpen: (open: boolean) => void;
  className?: string;
}

export default function CloseButton({ setOpen, className }: CloseButtonProps) {
  return (
    <button
      type="button"
      className={classNames(
        'rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-2 hover:ring-red-600 hover:bg-red-600 hover:text-white',
        className === null ? '' : className,
      )}
      onClick={() => {
        setOpen(false);
      }}
      data-testid="close-button"
    >
      <XMarkIcon className="w-4 h-4" />
    </button>
  );
}
