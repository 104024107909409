export function opportunityIdLinkConverter(userInput: string): string {
  // Salesforce Opportunity ID validation
  const pattern = /https:\/\/snowforce\.lightning\.force\.com\/lightning\/r\/Opportunity\/([a-zA-Z0-9]+)\/view/;
  const match = pattern.exec(userInput);
  if (match !== null) {
    return match[1]; // return the opportunity ID
  }
  return userInput;
}

// enum for validation levels from 0 to 3
export enum ValidationLevel {
  None = 0,
  AllowEmpty = 1,
  AllChecks = 2,
}

export function salesForceIdValidation(_id: string, level: ValidationLevel): string {
  if (level === ValidationLevel.AllChecks && _id.length === 0) {
    return 'ID required.';
  }

  const pattern = /[ %&?#]/;
  if (_id.length === 0) {
    return '';
  } else if (_id.length > 18) {
    return 'ID too long (expecting 18 characters), please check the ID in Salesforce.';
  } else if (_id.length < 18) {
    return 'ID too short (expecting 18 characters), please check the ID in Salesforce.';
  } else if (pattern.exec(_id) !== null) {
    return 'Spaces and special chars are unexpected.';
  }
  return '';
}
