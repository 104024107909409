import { ArrowUpOnSquareIcon, ClockIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { AuthContext } from 'react-oauth2-code-pkce';
import { useNavigate } from 'react-router-dom';
import Button from '../controls/Button';
import { classNames } from '../../utilities/styleUtils';
import ImportColumnLayout from '../layout/ImportColumnLayout';
import TierBadge from '../project/TierBadge';

export default function NewFromNotebook() {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const authContext = useContext(AuthContext);
  const { token } = authContext;
  const navigate = useNavigate();

  const importerApiUrl = 'http://localhost:8000/api';
  // const appName = "dataops-live-project-morph-local";

  const validateUrl = (url: string) => {
    const regex = /^(https?:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_.-]+\/[A-Za-z0-9_.-]+\/?$/;
    return regex.test(url);
  };

  // eslint-disable-next-line no-unused-vars
  const submitImportRequestAndReturnId = async (url: string): Promise<number | undefined> => {
    try {
      const response = await axios.post(`${importerApiUrl}/import`, {
        repo_path: url,
        oauth_token: token,
      });
      return response.data.id;
    } catch (error) {
      console.error('Error posting the URL:', error);
      setError('Failed to submit the URL. Please try again.');
      return undefined;
    }
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (validateUrl(url)) {
      setError('');
      // await installGitHubApp();
      const importId = await submitImportRequestAndReturnId(url);
      if (importId !== undefined) {
        navigate(`/new/from-notebook/import/${importId}`);
      }
      setError('');
    } else {
      setError('Invalid GitHub repository URL.');
    }
  };

  const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setUrl(event.target.value);
    if (error !== '') setError('');
  };

  return (
    <ImportColumnLayout>
      <div>
        <span className={classNames('text-purple-700', '', 'inline-flex rounded-lg p-3 ring-1 ring-purple-700')}>
          <PencilSquareIcon aria-hidden="true" className="h-6 w-6" />
        </span>
      </div>
      <h1 className="text-xl">New Solution from a Snowflake Notebook</h1>
      <div className="flex space-x-1">
        <div className="inline-flex items-center px-1 space-x-1 text-sm text-gray-800 rounded-md ring-1 ring-inset cursor-default ring-gray-500/10">
          <ClockIcon className="w-4 h-4" /> <span>Coming soon</span>
        </div>
        <TierBadge tier={3} />
      </div>

      <p>Share your Snowflake Notebook work with others by uploading a Snowflake Notebook to Solution Central.</p>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex flex-row justify-start gap-2">
          <div className="h-[33px] relative rounded-md bg-dataops-supporting-gray/50">
            <ArrowUpOnSquareIcon
              className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            <input
              disabled
              onChange={handleChange}
              placeholder="Browse for a Snowflake Notebook"
              className="h-[33px] w-96 border border-gray-200 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out"
              data-testid="search-input"
              value={url}
            />
          </div>
          <Button
            disabled
            type="submit"
            className="rounded-md bg-dataops-secondary-dark-blue text-white hover:bg-dataops-secondary-dark-blue/80 hover:text-white"
            data-testid="import-button"
          >
            Browse
          </Button>
        </div>
      </form>
      {error !== '' && <p>{error}</p>}
      <div className="w-full p-4 leading-5 space-y-2 rounded-md text-gray-700 bg-dataops-dark-blue/5">
        <h2 className="text-sm">Instructions</h2>
        <ol className="list-decimal pl-6 text-sm">
          <li>Open your Snowflake Notebook in Snowsight UI</li>
          <li>Export the notebook to a file</li>
          <li>Upload the file using the form above</li>
        </ol>
      </div>
      <Button
        intent={'light'}
        onClick={() => {
          navigate('/new');
        }}
      >
        Back
      </Button>
    </ImportColumnLayout>
  );
}
