import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function ImportColumnLayout({ children }: Props) {
  return (
    <div className="w-full min-h-screen-foot flex flex-col items-center py-16">
      <div className="space-y-8 px-2 w-full md:p-0 md:max-w-3xl">{children}</div>
    </div>
  );
}
