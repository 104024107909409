import React from 'react';
import { BuildingLibraryIcon, ClockIcon } from '@heroicons/react/24/outline';
import { FolderIcon, PencilSquareIcon, TagIcon } from '@heroicons/react/20/solid';

import { classNames } from '../../utilities/styleUtils';
import ImportColumnLayout from '../layout/ImportColumnLayout';
import TierBadge from '../project/TierBadge';
import Button from '../controls/Button';
import { useNavigate } from 'react-router-dom';
import { Input } from '../controls/Input';

function H1Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500"
    >
      <path
        fillRule="evenodd"
        d="M2.75 4a.75.75 0 0 1 .75.75v4.5h5v-4.5a.75.75 0 0 1 1.5 0v10.5a.75.75 0 0 1-1.5 0v-4.5h-5v4.5a.75.75 0 0 1-1.5 0V4.75A.75.75 0 0 1 2.75 4ZM13 8.75a.75.75 0 0 1 .75-.75h1.75a.75.75 0 0 1 .75.75v5.75h1a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 1 0-1.5h1v-5h-1a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default function NewFromTemplate() {
  const navigate = useNavigate();
  return (
    <ImportColumnLayout>
      <div>
        <span className={classNames('text-sky-700', '', 'inline-flex rounded-lg p-3 ring-1')}>
          <BuildingLibraryIcon aria-hidden="true" className="h-6 w-6" />
        </span>
      </div>
      <h1 className="text-xl">New Solution from SSC Framework Template</h1>
      <div className="flex space-x-1">
        <div className="inline-flex items-center px-1 space-x-1 text-sm text-gray-800 rounded-md ring-1 ring-inset cursor-default ring-gray-500/10">
          <ClockIcon className="w-4 h-4" /> <span>Coming soon</span>
        </div>
        <TierBadge tier={1} />
      </div>

      <p>Build a demo using the framework template foundation and development workflow.</p>
      <div className="space-y-16">
        <section className="space-y-4 text-sm max-w-lg">
          <div className="space-y-2">
            <h3 className="text-base text-gray-500">Solution information</h3>
            <Input label="Title" icon={H1Icon} placeholder="What will appear as the name of your demo?" />
            <Input label="Description" icon={PencilSquareIcon} placeholder="What does your demo show?" />
          </div>
        </section>
        <section className="space-y-4 text-sm max-w-lg">
          <div className="space-y-2">
            <h3 className="text-base text-gray-500">Tags</h3>
            <Input icon={TagIcon} placeholder="What features and use cases are being used?" />
          </div>
        </section>
        <section className="space-y-4 text-sm">
          <div className="space-y-2">
            <h3 className="text-base text-gray-500">Upload content</h3>
            <div className="flex items-center space-x-1">
              <Input icon={FolderIcon} placeholder="Browse for files" />
              <Button intent={'primary'} disabled>
                Browse
              </Button>
            </div>
            <div className="w-full p-4 leading-5 space-y-2 rounded-md text-gray-700 bg-dataops-dark-blue/5">
              <p>Upload content you have already created, like a Notebook or Streamlit app.</p>
            </div>
          </div>
        </section>
      </div>
      <Button intent={'primary'} disabled>
        Create new solution
      </Button>
      <Button
        intent={'light'}
        onClick={() => {
          navigate('/new');
        }}
      >
        Back
      </Button>
    </ImportColumnLayout>
  );
}
