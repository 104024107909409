import React from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from '../../utilities/styleUtils';

interface IExperimentalFeatureToggleProps {
  label: string;
  checked: boolean;
  onChange: () => void;
}

export default function ExperimentalFeatureToggle(props: IExperimentalFeatureToggleProps) {
  return (
    <Switch.Group as="div" className="flex items-center my-1">
      <Switch
        checked={props.checked}
        onChange={props.onChange}
        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full"
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? 'bg-dataops-light-blue' : 'bg-gray-200',
            'pointer-events-none absolute mx-auto h-3 w-6 rounded-full transition-colors duration-200 ease-in-out',
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            props.checked ? 'translate-x-5' : 'translate-x-1',
            'pointer-events-none absolute left-0 inline-block h-4 w-4 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="text-sm">
        <span className="text-gray-600 cursor-pointer">{props.label}</span>
      </Switch.Label>
    </Switch.Group>
  );
}
