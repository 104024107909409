import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { classNames } from '../../utilities/styleUtils';

interface Props {
  tier: number;
}

function getTierLabel(tier: number): string {
  switch (tier) {
    case 1:
      return 'Certified solution';
    case 2:
      return 'Solution';
    case 3:
      return 'Community scaled';
    case 4:
      return 'Community';
    default:
      return '';
  }
}

export default function TierBadge({ tier }: Props) {
  //   return <div>{getTierLabel(tier)}</div>;
  const tierLabel = getTierLabel(tier);

  if (tierLabel === '') {
    console.log('default tier');
    return null;
  }
  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium ring-1 ring-inset cursor-default ',
        tier === 1 ? 'bg-purple-100 text-purple-800 ring-purple-500/30' : 'text-gray-800 ring-gray-500/10',
      )}
    >
      {tierLabel}
      {tier === 1 && <CheckBadgeIcon className="h-4 w-4 ml-1 text-purple-700" />}
    </span>
  );
}
