import React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { classNames } from '../../utilities/styleUtils';

const buttonConfig: {
  variants: {
    intent: {
      primary: string[];
      secondary: string[];
      danger: string[];
      warning: string[];
      subtle: string[];
      light: string[];
      secondaryAction: string[];
      custom: string[];
    };
    size: {
      small: string[];
      medium: string[];
      large: string[];
    };
  };
  defaultVariants: {
    intent: 'primary' | 'secondary' | 'danger' | 'warning' | 'subtle' | 'light' | 'secondaryAction' | 'custom';
    size: 'small' | 'medium' | 'large';
  };
} = {
  variants: {
    intent: {
      primary: ['bg-dataops-dark-blue', 'text-white', 'shadow-sm', 'hover:bg-hover-dark-blue', 'disabled:bg-gray-300'],
      secondary: [
        'bg-dataops-light-blue',
        'text-white',
        'shadow-sm',
        'hover:bg-hover-light-blue',
        'disabled:bg-gray-300',
      ],
      danger: ['bg-[#ec6a46]', 'text-white', 'shadow-sm', 'hover:bg-red-600', 'disabled:bg-[#ec6a46]/70'],
      warning: ['bg-gray-400', 'text-black', 'shadow-sm', 'hover:bg-gray-500', 'disabled:bg-gray-400/70'],
      subtle: ['bg-white', 'text-gray-900', 'ring-0', 'shadow-none', 'hover:underline'],
      light: [
        'bg-white',
        'text-gray-900',
        'shadow-sm',
        'ring-1',
        'ring-gray-300',
        'hover:bg-gray-200',
        'disabled:bg-gray-500',
        'disabled:hover:!bg-gray-500',
        'disabled:text-white',
      ],
      secondaryAction: [
        'bg-action-button-background/80',
        'text-action-button-text',
        'hover:bg-action-button-hover-background',
        'disabled:bg-gray-500',
        'shadow-sm',
        'w-full',
      ],
      custom: [],
    },
    size: {
      small: ['text-sm', 'py-1', 'px-1'],
      medium: ['text-sm', 'py-1', 'px-3', 'h-[32px]'],
      large: ['text-sm', 'py-2', 'px-3.5'],
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'medium',
  },
};

const button = cva('button', buttonConfig);
const link = cva('a', buttonConfig);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof button> {
  url?: string;
}

const Button: React.FC<ButtonProps> = ({ url, className, intent, size, ...props }) => {
  if (url !== undefined) {
    return (
      <a
        href={url}
        target={'_blank'}
        rel="noreferrer"
        className={classNames('font-semibold rounded-md flex items-center', link({ intent, size, className }))}
      >
        {props.children}
      </a>
    );
  }

  return (
    <button
      className={classNames('font-semibold rounded-md flex items-center', button({ intent, size, className }))}
      {...props}
    />
  );
};

export default Button;
