import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Button from '../controls/Button';
import { useNavigate } from 'react-router-dom';

export default function NoMatch() {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen-foot">
      <div className="flex flex-col justify-center text-center pt-16">
        <QuestionMarkCircleIcon className="h-12 w-12 text-gray-400 mx-auto" />
        <h3 className="mt-2 text-lg text-gray-900">Not a page we recognize</h3>
        <p className="mt-1 text-sm text-gray-500">Get started by searching.</p>
        <div className="mt-6 flex justify-center">
          <Button
            intent={'primary'}
            onClick={() => {
              navigate('/');
            }}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}
