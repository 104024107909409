import React, { useContext, useEffect, useRef } from 'react';
import './App.css';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch,
} from '@elastic/react-search-ui';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import type { SearchDriverOptions } from '@elastic/search-ui';
import { useAppDispatch, useAppSelector } from './hooks';

import { getConfig } from './config/config-helper';
import ProjectCard from './components/project/ProjectCard';
import SnowflakeAccountsViewer from './components/modals/accounts/SnowflakeAccountsViewer';

import AppBar from './components/nav/AppBar';
import Footer from './components/pages/Footer';
import SearchBar from './components/controls/SearchBar';
import FacetMultiSelectView from './components/controls/FacetMultiSelectView';
import { parseGuid } from './utilities/parsers';
import * as Sentry from '@sentry/react';
import BroadcastBanners from './components/notification/BroadcastBanners';
import broadcastData from './broadcasts/data.json';
import type { IBroadcast } from './interfaces/IBroadcast';
import { fetchDocsPipelines, fetchUser, selectUser } from './reducers/accountSlice';
import { fetchDeployments } from './reducers/deploymentsSlice';
import ProjectCardSkeleton from './components/project/ProjectCardSkeleton';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import NewFromGitPath from './components/pages/NewFromGitPage';
import NewSolutionPage from './components/pages/NewSolutionPage';
import Import from './components/pages/Import';
import NewFromNotebook from './components/pages/NewFromNotebookPage';
import Imported from './components/pages/Imported';
import NewFromTemplate from './components/pages/NewFromTemplate';
import NoMatch from './components/pages/NoMatch';
declare global {
  interface Window {
    Appcues: any;
  }
}

const { searchKey, endpointBase, engineName, facets, disjunctiveFacets } = getConfig();

const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  endpointBase,
});

const config: SearchDriverOptions = {
  searchQuery: {
    facets,
    disjunctiveFacets,
  },
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
};

function App() {
  const { token, login, logOut, loginInProgress } = useContext<IAuthContext>(AuthContext);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);

  // We need a reference to access the current value in the timeout callback
  const tokenRef = useRef(token);

  function loginWithRetry(retrigger: boolean) {
    // Already logged in, nothing to do
    if (tokenRef.current !== '') {
      return;
    }

    if (!loginInProgress || retrigger) {
      login();
    }

    setTimeout(() => {
      loginWithRetry(true);
    }, 5000);
  }

  useEffect(() => {
    tokenRef.current = token;
    loginWithRetry(false);
  }, [token]);

  useEffect(() => {
    if (token !== '') {
      dispatch(fetchUser(token)).catch((err) => {
        console.log('Fetch username error:', err);
      });
    }
  }, [token]);

  useEffect(() => {
    const userId = parseGuid(currentUser?.id ?? '');
    if (userId === '') {
      return;
    }
    if (window.Appcues !== undefined) {
      window.Appcues.identify(
        userId, // unique, required
        {
          email: currentUser?.emails?.nodes[0].email, // Current user's email
          username: currentUser?.username, // Current user's username
          environment: process.env.REACT_APP_ENV,
        },
      );
    }
    Sentry.setContext('user', {
      email: currentUser?.emails?.nodes[0].email,
      username: currentUser?.username,
    });
  }, [currentUser]);

  useEffect(() => {
    if (token === '' || loginInProgress || currentUser?.username === '') {
      return;
    }
    if (currentUser?.username !== undefined) {
      dispatch(fetchDeployments({ token, username: currentUser?.username })).catch((err) => {
        console.log('Fetch deployments error:', err);
      });
      dispatch(fetchDocsPipelines(token)).catch((err) => {
        console.log('Fetch docs pipelines error:', err);
      });
    }
  }, [token, loginInProgress, currentUser?.username]);

  function tryRefreshProjects() {
    if (currentUser?.username !== undefined) {
      dispatch(fetchDeployments({ token, username: currentUser?.username })).catch((err) => {
        console.log('Fetch deployments error:', err);
      });
    }
  }

  const broadcasts: IBroadcast[] = broadcastData;

  return (
    <Router>
      <div className="min-w-[720px]">
        <Routes>
          <Route
            path="/"
            element={
              <div className="mt-[3.9rem]">
                <AppBar
                  username={currentUser?.username}
                  emailAddress={currentUser?.emails.nodes[0].email}
                  logOut={logOut}
                  refreshProjects={tryRefreshProjects}
                />
                <Outlet />
              </div>
            }
          >
            <Route path="*" element={<NoMatch />} />
            <Route path="/new" element={<NewSolutionPage />} />
            <Route path="/new/from-framework" element={<NewFromTemplate />} />
            <Route path="/new/from-notebook" element={<NewFromNotebook />} />
            <Route path="/new/from-git" element={<NewFromGitPath />} />
            <Route path="/new/from-git/:id" element={<Imported />} />
            <Route path="/new/from-git/import/:id" element={<Import />} />
            {/* <Route path="/new/from-git/:id" element={<Imported />} /> */}
            <Route
              path="/"
              element={
                <div>
                  <BroadcastBanners broadcastData={broadcasts} />
                  <SearchProvider config={config}>
                    <WithSearch
                      mapContextToProps={({ wasSearched, setFilter, searchTerm }) => ({
                        wasSearched,
                        setFilter,
                        searchTerm,
                      })}
                    >
                      {({ wasSearched, setFilter, searchTerm }) => {
                        if (searchTerm === '') {
                          setTimeout(() => {
                            setFilter('release_status', 'Published', 'all');
                          }, 50);
                        }
                        return (
                          <div className="flex flex-col items-center  bg-gray-100 min-h-screen-foot">
                            <div className="lg:max-w-7xl sm:w-full">
                              <ErrorBoundary>
                                <div className=" space-y-4">
                                  <div className="px-8 pt-8">
                                    <SearchBox debounceLength={0} inputView={SearchBar} />
                                  </div>
                                  <div className="px-8 min-h-[80px] min-[1146px]:space-x-2 flex flex-col min-[1146px]:flex-row md:flex-col">
                                    <div className="w-60">
                                      <div className="absolute sm:hidden lg:block">
                                        <div className="pt-1 text-gray-600">Vertical</div>
                                        <div className="rounded-md w-[237px] h-[36px] border-2 border-grey-200 bg-grey-50"></div>
                                      </div>
                                      <Facet
                                        field="vertical_applicability"
                                        label="Vertical"
                                        show={30}
                                        view={FacetMultiSelectView}
                                      />
                                    </div>

                                    <div className="w-60">
                                      <div className="absolute sm:hidden lg:block">
                                        <div className="pt-1 text-gray-600">Use case</div>
                                        <div className="rounded-md w-[237px] h-[36px] border-2 border-grey-200 bg-grey-50"></div>
                                      </div>
                                      <Facet field="use_case" label="Use case" show={30} view={FacetMultiSelectView} />
                                    </div>
                                    <div className="w-60">
                                      <div className="absolute sm:hidden lg:block">
                                        <div className="pt-1 text-gray-600">Workload</div>
                                        <div className="rounded-md w-[237px] h-[36px] border-2 border-grey-200 bg-grey-50"></div>
                                      </div>
                                      <Facet field="workload" label="Workload" show={30} view={FacetMultiSelectView} />
                                    </div>
                                    <div className="w-60">
                                      <div className="absolute sm:hidden lg:block">
                                        <div className="pt-1 text-gray-600">Feature</div>
                                        <div className="rounded-md w-[237px] h-[36px] border-2 border-grey-200 bg-grey-50"></div>
                                      </div>
                                      <Facet
                                        field="snowflake_feature"
                                        label="Feature"
                                        show={30}
                                        view={FacetMultiSelectView}
                                      />
                                    </div>
                                    <div className="w-60">
                                      <div className="absolute sm:hidden lg:block">
                                        <div className="pt-1 text-gray-600">Release Status</div>
                                        <div className="rounded-md w-[237px] h-[36px] border-2 border-grey-200 bg-grey-50"></div>
                                      </div>
                                      <Facet
                                        field="release_status"
                                        label="Release Status"
                                        show={30}
                                        view={FacetMultiSelectView}
                                      />
                                    </div>
                                  </div>
                                  <div className="px-8 flex justify-between">
                                    {/* {(wasSearched as boolean) && (
                            <Sorting label={'Sort by'} sortOptions={sortFields} className="basis-40" />
                          )} */}
                                    {(wasSearched as boolean) && <PagingInfo />}
                                    {(wasSearched as boolean) && <ResultsPerPage className="z-10" />}
                                  </div>
                                  <div className="px-8 ">
                                    {token !== '' && currentUser?.username !== '' ? (
                                      <Results
                                        titleField="title"
                                        urlField="nps_link"
                                        thumbnailField="image_url"
                                        shouldTrackClickThrough
                                        className="space-y-4"
                                        resultView={(props) =>
                                          ProjectCard({
                                            ...props,
                                            className: 'project',
                                            titleField: 'title',
                                            urlField: 'urlFIeld',
                                            thumbnailField: 'thumbnailField',
                                            onClickLink: () => {},
                                            refreshProjects: tryRefreshProjects,
                                            refreshSolutionDeployments: tryRefreshProjects,
                                          })
                                        }
                                      />
                                    ) : (
                                      <ul className="space-y-4">
                                        <ProjectCardSkeleton />
                                        <ProjectCardSkeleton />
                                        <ProjectCardSkeleton />
                                        <ProjectCardSkeleton />
                                        <ProjectCardSkeleton />
                                      </ul>
                                    )}
                                  </div>
                                  <div className="px-8 pb-16">{(wasSearched as boolean) && <Paging />}</div>
                                </div>
                              </ErrorBoundary>
                            </div>
                          </div>
                        );
                      }}
                    </WithSearch>
                  </SearchProvider>
                </div>
              }
            />
          </Route>
        </Routes>

        <SnowflakeAccountsViewer />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
