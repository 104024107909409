import React from 'react';
import { classNames } from '../../utilities/styleUtils';

export interface IReleaseStatusBadgeProps {
  label: string;
}

const ReleaseStatusBadge: React.FC<IReleaseStatusBadgeProps> = (props) => {
  function color(label: string) {
    switch (label) {
      case 'Published':
        return 'bg-dataops-dark-green/10 text-green-800 ring-dataops-dark-green/50';
      case 'Draft':
        return 'bg-yellow-500/10 text-orange-800 ring-yellow-500/50';
      default:
        return 'bg-gray-50 text-gray-800 ring-gray-500/10';
    }
  }
  return (
    <div
      className={classNames(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10',
        color(props.label),
      )}
    >
      {props.label}
    </div>
  );
};

export default ReleaseStatusBadge;
