import React from 'react';
import GridList, { type IAction } from '../controls/GridList';
import { BuildingLibraryIcon, GlobeAltIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ImportColumnLayout from '../layout/ImportColumnLayout';

export default function NewSolutionPage() {
  const navigate = useNavigate();
  const actions: IAction[] = [
    {
      title: 'New from Git repository',
      desc: 'Use your existing git repository to add a listing entry to Solution Central.',
      onClick: (action, actionIdx) => {
        navigate('/new/from-git');
      },
      tier: 4,
      icon: GlobeAltIcon,
      iconForeground: 'dataops-light-green',
      iconBackground: 'dataops-light-green/10',
      metricsData: {
        event_name: 'dataops-ssc-import-git-clicked',
        event_source: 'frostbyte-deployment-portal',
      },
    },
    {
      title: 'New from Snowflake Notebook',
      desc: 'Use your existing Snowflake notebook to add a listing entry to Solution Central.',
      onClick: (action, actionIdx) => {
        navigate('/new/from-notebook');
      },
      tier: 3,
      icon: PencilSquareIcon,
      iconForeground: 'purple-700',
      iconBackground: 'purple-700',
      metricsData: {
        event_name: 'dataops-ssc-import-snowflake-notebook-clicked',
        event_source: 'frostbyte-deployment-portal',
      },
    },
    {
      title: 'New from SSC Framework Template',
      desc: 'Use the Solution Central Framework template to start a new listing entry.',
      onClick: (action, actionIdx) => {
        navigate('/new/from-framework');
      },
      tier: 1,
      icon: BuildingLibraryIcon,
      iconForeground: 'sky-700',
      iconBackground: 'sky-50',
      metricsData: {
        event_name: 'dataops-ssc-import-ssc-framework-clicked',
        event_source: 'frostbyte-deployment-portal',
      },
    },
  ];

  // const NUMBER_OF_STEPS = pages.length;

  return (
    <ImportColumnLayout>
      <GridList actions={actions} />
    </ImportColumnLayout>
  );
}
