import React from 'react';
import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import type { ISnowflakeAccount } from '../../interfaces/ISnowflakeAccount';
import { classNames } from '../../utilities/styleUtils';

interface IPersistedAccountSelectorProps {
  account: string;
  setAccount: (account: string) => void;
  setUsername: (username: string) => void;
  snowflakeAccounts: ISnowflakeAccount[];
}

export default function PersistedAccountSelector(props: IPersistedAccountSelectorProps) {
  const filteredAccounts =
    props.account === ''
      ? props.snowflakeAccounts
      : props.snowflakeAccounts.filter((snowflakeAccount) => {
          return snowflakeAccount.accountLocator.toLowerCase().includes(props.account.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={props.account}
      onChange={(newAccount) => {
        if (newAccount === null) {
          return;
        }
        props.setAccount(newAccount);
        const selectedAccount = props.snowflakeAccounts.find(
          (snowflakeAccount) => snowflakeAccount.accountLocator === newAccount,
        );
        if (selectedAccount !== undefined) {
          props.setUsername(selectedAccount.username);
        }
      }}
    >
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(e) => {
            props.setAccount(e.target.value);
            const selectedAccount = props.snowflakeAccounts.find(
              (snowflakeAccount) => snowflakeAccount.accountLocator === e.target.value,
            );
            if (selectedAccount !== undefined) {
              props.setUsername(selectedAccount.username);
            }
          }}
          value={props.account}
          required
          placeholder='e.g. "abcdef-ij12345"'
          data-testid="snowflake-account-input"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredAccounts.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredAccounts.map((snowflakeAccount) => (
              <Combobox.Option
                key={`${snowflakeAccount.accountLocator}-${snowflakeAccount.username}`}
                value={snowflakeAccount.accountLocator}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900',
                    active ? 'bg-gray-100' : '',
                  )
                }
              >
                {({ selected }) => (
                  <>
                    <div className="leading-tight">
                      <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
                        {snowflakeAccount.accountLocator}
                      </span>
                      <span className="text-xs text-gray-500">{snowflakeAccount.username}</span>
                    </div>
                    {selected && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
