import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import ProjectDeploymentCard from '../project/ProjectDeploymentCard';
import { useAppSelector } from '../../hooks';
import { selectSocialFeaturesEnabled } from '../../reducers/projectsSlice';
import SocialFeatures from '../common/SocialFeatures';
import ReleaseStatusBadge from '../project/ReleaseStatusBadge';
import TagSection from '../common/TagSection';
import Button from '../controls/Button';
import CloseButton from '../controls/CloseButton';
import type { IDeployment } from '../../reducers/deploymentsSlice';
import Pagination, { type IPageState } from '../controls/Pagination';
import FilterInput from '../controls/FilterInput';
import { type ISolution } from '../../reducers/solutionsSlice';

interface Props {
  solution: ISolution;
  open: boolean;
  setOpen: (open: boolean) => void;
  deployments: IDeployment[];
  refreshProjects: () => void;
  result: any;
  setSetupModalOpen: (open: boolean) => void;
  refreshSolutionDeployments: () => void;
}

export default function ProjectDeploymentModal({
  solution,
  open,
  setOpen,
  deployments: projectInstances,
  refreshProjects,
  result,
  setSetupModalOpen,
  refreshSolutionDeployments,
}: Props) {
  const [totalDeployments, setTotalDeployments] = useState<number>(projectInstances.length);
  const [filter, setFilter] = useState<string>('');
  const [pageState, setPageState] = useState<IPageState>({
    currentPage: 1,
    itemsPerPage: 5,
  });
  const startIndex = (pageState.currentPage - 1) * pageState.itemsPerPage;
  const endIndex = startIndex + pageState.itemsPerPage;
  const filteredProjectInstances = projectInstances
    .filter((deployment) => deployment.name.toLowerCase().includes(filter.toLowerCase()))
    .slice(startIndex, endIndex);

  const projectMainUrl = result?.project_main_url?.raw;
  const releaseStatus = result?.release_status?.raw;
  const version = result?.version?.raw;
  const name = result?.name?.raw;
  const icon = result?.icon?.raw;
  const description = result?.description?.raw;
  const verticalApplicability = result?.vertical_applicability?.raw;
  const useCase = result?.use_case?.raw;
  const creator = result?.creator?.raw;
  const socialFeaturesEnabled = useAppSelector(selectSocialFeaturesEnabled);
  const certified = result?.certified?.raw;

  useEffect(() => {
    // Count the total number of deployments filtered
    if (filter !== '') {
      setTotalDeployments(
        projectInstances.filter((deployment) => deployment.name.toLowerCase().includes(filter.toLowerCase())).length,
      );
    }
    setPageState({ ...pageState, currentPage: 1 });
  }, [filter]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30 " onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg divide-y-2 bg-gray-50 text-left shadow-xl transition-all sm:my-24 sm:w-full sm:max-w-7xl">
                <div className="px-4 py-5 sm:px-6 flex flex-row content-center items-center">
                  <div className="shrink-0 ">
                    <div className="w-8">
                      <img src={icon} />
                    </div>
                  </div>
                  <div>
                    <h3 className="ml-4 text-xl font-semibold leading-6 text-gray-900 hover:text-sky-500">
                      <a href={projectMainUrl} target="_blank" rel="noreferrer">
                        {name}
                      </a>
                    </h3>
                  </div>
                  {version !== undefined && (
                    <div className="ml-2 self-end pb-[4px]">
                      <div className="text-sm text-gray-600">{version}</div>
                    </div>
                  )}
                  <div className="flex-1 flex justify-end ml-[auto] items-center space-x-1">
                    {socialFeaturesEnabled && (
                      <SocialFeatures
                        solution={solution}
                        releaseStatus={releaseStatus}
                        refreshSolution={refreshSolutionDeployments}
                      />
                    )}
                    {certified !== undefined && certified === 'true' && <ReleaseStatusBadge label="Certified" />}
                    <ReleaseStatusBadge label={releaseStatus} />
                    {creator !== '' && <ReleaseStatusBadge label={creator} />}
                  </div>
                  <CloseButton setOpen={setOpen} className="ml-6" />
                </div>
                <div className="flex flex-row p-6 space-x-2">
                  <div id="details" className="flex basis-5/6 flex-col grow space-y-4">
                    <div>
                      <dl className="">
                        <dt className="text-sm font-medium text-gray-500">Description</dt>
                        <dd className="text-sm text-gray-900">{description}</dd>
                      </dl>
                    </div>
                    <div className="">
                      <dl className="">
                        <dt className="text-sm font-medium text-gray-500">Industry vertical</dt>
                        <dd className="text-sm text-gray-900">
                          <TagSection tags={verticalApplicability} />
                        </dd>
                      </dl>
                      <dl className="">
                        <dt className="text-sm font-medium text-gray-500">Use case</dt>
                        <dd className="text-sm text-gray-900">
                          <TagSection tags={useCase} />
                        </dd>
                      </dl>
                    </div>
                  </div>
                  {projectInstances.length > 0 && (
                    <div className=" text-center">
                      <div className="flex items-center justify-center">
                        <Button
                          id="setup-new-solution-button"
                          onClick={() => {
                            setOpen(false);
                            setSetupModalOpen(true);
                          }}
                          intent="light"
                          size="large"
                          className=""
                        >
                          Setup new solution
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {projectInstances.length === 0 && (
                  <div className="text-center p-8">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        vectorEffect="non-scaling-stroke"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                      />
                    </svg>
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No deployments</h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new deployment of a solution.
                    </p>
                    <div className="mt-6 flex items-center justify-center">
                      <Button
                        id="setup-new-solution-button"
                        onClick={() => {
                          setOpen(false);
                          setSetupModalOpen(true);
                        }}
                        intent="primary"
                        size="large"
                        className=""
                      >
                        Setup new solution
                      </Button>
                    </div>
                  </div>
                )}
                <FilterInput filter={filter} setFilter={setFilter} />
                {filteredProjectInstances.map((projectInstance) => {
                  return (
                    <ProjectDeploymentCard
                      key={projectInstance.name}
                      projectInstance={projectInstance}
                      refreshProjects={refreshProjects}
                    />
                  );
                })}
                <Pagination
                  currentPage={pageState.currentPage}
                  itemsPerPage={pageState.itemsPerPage}
                  onPageChange={(pageNumber: number) => {
                    setPageState({ ...pageState, currentPage: pageNumber });
                  }}
                  totalItems={totalDeployments}
                />
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
