import React, { useContext, useState, Fragment } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import Spinner from '../common/Spinner';
import ErrorMessages from '../notification/ErrorMessages';
import WarningMessage from '../notification/WarningMessage';
import Button from '../controls/Button';
import axios from 'axios';
import { parseGuid } from '../../utilities/parsers';
import { getConfig } from '../../config/config-helper';
import { classNames } from '../../utilities/styleUtils';

interface IUpdateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelButtonRef: React.RefObject<HTMLButtonElement>;
  projectInstanceName: string;
  projectInstanceId: string;
  projectId: string;
  updateUrl: string;
  projectAccountName: string;
  projectUsername: string;
  prefillProjectPassword: string;
  refreshProject: (projectId: string) => void;
}

export default function UpdateModal(props: IUpdateModalProps) {
  const { token } = useContext<IAuthContext>(AuthContext);
  const [loading, setLoading] = useState(false);
  const [snowflakeAccountPassword, setPassword] = useState(props.prefillProjectPassword);
  const [hidden, setHidden] = useState(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [showAdvancedFields, setShowAdvancedFields] = useState<boolean>(false);
  const toggleHidden = (e: React.FormEvent) => {
    e.preventDefault();
    setHidden(!hidden);
  };
  const { dataopsCatalogApiUpdateEndpoint, dataopsliveBaseUrl } = getConfig();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const requestBody = {
      project_id: parseGuid(props.projectId),
      snowflake_password: snowflakeAccountPassword,
      platform_url: `${dataopsliveBaseUrl}/`,
    };
    setLoading(true);
    setErrors([]);
    axios
      .post(dataopsCatalogApiUpdateEndpoint, requestBody, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        props.setOpen(false);
        props.refreshProject(props.projectInstanceId);
      })
      .catch((err) => {
        console.log('Failed to update solution: ', {
          code: err.code,
          message: err.message,
          reason: err.response.data.reason,
        });
        setErrors(err.response.data.reason);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-30" initialFocus={props.cancelButtonRef} onClose={props.setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-gray-50 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <form onSubmit={onSubmit}>
                  <div className="space-y-10 divide-y divide-gray-900/10 p-6">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                      <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Update</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                          Use this form to update your solution instance to the latest template version.
                        </p>
                      </div>

                      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6">
                          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                              <div className="text-sm font-semibold leading-7 text-gray-700">
                                {props.projectInstanceName}
                              </div>
                            </div>
                            <div
                              className="sm:col-span-5 flex items-center text-gray-600 hover:text-sky-800 hover:underline cursor-pointer"
                              onClick={() => {
                                setShowAdvancedFields(!showAdvancedFields);
                              }}
                            >
                              <div className="text-sm">Advanced</div>
                              <ChevronUpIcon
                                className={classNames(
                                  showAdvancedFields ? 'rotate-180 transform' : 'rotate-90 transform',
                                  'h-4 w-4 ml-1',
                                )}
                              />
                            </div>
                            {showAdvancedFields && (
                              <>
                                <div className="sm:col-span-5">
                                  <div className="flex items-center">
                                    <label
                                      htmlFor="customer-url"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Snowflake account locator
                                    </label>
                                  </div>
                                  <div className="mt-2">
                                    <code>{props.projectAccountName}</code>
                                  </div>
                                </div>
                                <div className="sm:col-span-4">
                                  <label
                                    htmlFor="snowflake-username"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Snowflake username
                                  </label>
                                  <div className="mt-2">
                                    <code>{props.projectUsername}</code>
                                  </div>
                                </div>
                                <div className="sm:col-span-5">
                                  <label
                                    htmlFor="snowflake-password"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                  >
                                    Snowflake password
                                  </label>
                                  <div className="mt-2 flex items-center">
                                    <input
                                      type={hidden ? 'password' : 'text'}
                                      name="snowflake-password"
                                      id="snowflake-password"
                                      value={snowflakeAccountPassword}
                                      disabled={loading}
                                      onChange={(e) => {
                                        setPassword(e.target.value);
                                      }}
                                      required
                                      dir="ltr"
                                      className="rounded-l-md block w-full border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                                    />
                                    <button
                                      onClick={(e) => {
                                        toggleHidden(e);
                                      }}
                                      dir="rtl"
                                      className="rounded-r-md bg-white py-1 px-1.5 h-9 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 hover:outline hover:outline-offset-2 hover:outline-gray-200"
                                    >
                                      {hidden ? 'Reveal' : 'Conceal'}
                                    </button>
                                  </div>
                                  <WarningMessage
                                    message={`Please make sure to provide the password for user ${props.projectUsername}.`}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {errors !== undefined && errors.length > 0 && <ErrorMessages errors={errors} />}

                  <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 bg-white px-4 py-4 sm:px-8">
                    <Button
                      type="button"
                      disabled={loading}
                      onClick={() => {
                        setErrors([]);
                        props.setOpen(false);
                      }}
                      intent="subtle"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={loading} intent="primary" size="large">
                      {loading && <Spinner className="mr-1" />}
                      Update Solution
                    </Button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
