import { getConfig } from '../config/config-helper';
import { store } from '../store';
import { selectUser } from '../reducers/accountSlice';
import { parseGuid } from './parsers';

function getUserFromStore() {
  // Dispatch the action with the desired payload
  return selectUser(store.getState());
}

export function sendMetrics(analyticsData: any) {
  const { dataopsShareEnvironment } = getConfig();
  const user = getUserFromStore();
  const enrichedAnalyticsData = {
    ...analyticsData,
    properties: {
      ...analyticsData.properties,
      environment: dataopsShareEnvironment,
      userId: parseGuid(user?.id ?? ''),
      userEmail: user?.emails.nodes[0].email,
      currentUrl: window.location.href,
    },
  };
  const analyticsUrl = 'https://ms5iwqbfij.execute-api.eu-west-2.amazonaws.com/metric';
  fetch(analyticsUrl, {
    method: 'POST',
    mode: 'no-cors',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(enrichedAnalyticsData),
  }).catch(() => {});
}
