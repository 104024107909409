import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import axios from 'axios';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { type IAuthContext, AuthContext } from 'react-oauth2-code-pkce';
import { extractProjectPath, getEnvVariableValue, parseGuid } from '../../utilities/parsers';
import ProjectStatus from './ProjectStatus';
import {
  ArrowTopRightOnSquareIcon,
  CircleStackIcon,
  HomeIcon,
  RocketLaunchIcon,
  ShareIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '../../utilities/styleUtils';
import ValidationStatus from '../modals/validation/ValidationStatus';
import { getJobByNames, getMainPipeline } from '../../utilities/pipelines';
import { useMainPipelineStatus, useValidationPipelineStatus } from '../../hooks/usePipelineStatus';
import ProjectInstanceCardDetails from './ProjectInstanceCardDetails';
import HolParticipantDetails from './HolParticipantDetails';

import Spinner from '../common/Spinner';
import Button from '../controls/Button';
import HomepageLaunchModal from '../modals/HomepageLaunchModal';
import DeleteModal from '../modals/DeleteModal';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectHolEnabled } from '../../reducers/projectsSlice';
import { getConfig } from '../../config/config-helper';
import {
  type IJob,
  type IPipeline,
  fetchDeploymentExtra,
  type IDeployment,
  selectIsReadOnly,
  fetchDeploymentUpdates,
} from '../../reducers/deploymentsSlice';
import PipelineProgressBar from '../common/PipelineProgressBar';
import { selectUser } from '../../reducers/accountSlice';
import ShareModal from '../modals/share/ShareModal';
import { type RootState } from '../../store';
import { selectSolution } from '../../reducers/solutionsSlice';

type HomepageStatus = 'Available' | 'Pending' | 'Failed';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: '200px',
};

interface TooltipData {
  description: string;
}

export interface IProjectDeploymentCardProps {
  projectInstance: IDeployment;
  refreshProjects: () => void;
}

function getHomepageJob(pipeline: IPipeline): IJob | undefined {
  return getJobByNames(pipeline, ['Render Solution Homepage', 'Build Solution Homepage']);
}

export default function ProjectDeploymentCard({ projectInstance, refreshProjects }: IProjectDeploymentCardProps) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const dispatch = useAppDispatch();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [launchHomepageModalOpen, setLaunchHomepageModalOpen] = useState<boolean>(false);
  const [homepageStatus, setHomepageStatus] = useState<HomepageStatus>('Pending');
  const [solutionHomepageUrl, setSolutionHomepageUrl] = useState<string>();
  const { token } = useContext<IAuthContext>(AuthContext);
  const validationStatus = useValidationPipelineStatus(projectInstance);
  const mainPipelineStatus = useMainPipelineStatus(projectInstance);
  const cancelButtonRef = useRef(null);
  const [hasTeardownTemplate, setHasTeardownTemplate] = useState<boolean>(false);
  const isReadOnlyUser = useAppSelector(selectIsReadOnly);
  const holEnabled = useAppSelector(selectHolEnabled);
  const username = useAppSelector(selectUser)?.username ?? '';
  const { dataopsliveBaseUrl } = getConfig();
  const templateProjectId =
    projectInstance.ciVariables.find((variable: any) => variable.key === 'DATAOPS_CATALOG_SOLUTION_TEMPLATE_ID')
      ?.value ?? 'unknown';
  const customerName = getEnvVariableValue(projectInstance.ciVariables, 'FROSTBYTE_CUSTOMER_NAME');
  const customerLogoUrl = getEnvVariableValue(projectInstance.ciVariables, 'FROSTBYTE_CUSTOMER_LOGO_URL');
  const useCaseId = getEnvVariableValue(projectInstance.ciVariables, 'DATAOPS_CATALOG_OPPORTUNITY_ID');
  const mainPipeline = useMemo(() => getMainPipeline(projectInstance.pipelines), [projectInstance.pipelines]);
  const projectPipelinesUrl = `${projectInstance.webUrl}/-/pipelines`;
  const solution = useAppSelector((state: RootState) => selectSolution(state, templateProjectId));

  function dispatchDeploymentExtra() {
    dispatch(fetchDeploymentExtra({ token, fullPath: projectInstance.fullPath, retryCount: 0, maxRetry: 20 })).catch(
      (err) => err,
    );
  }
  function dispatchDeploymentUpdates() {
    if (projectInstance.requestExtraStatus !== 'loading') {
      dispatch(
        fetchDeploymentUpdates({
          token,
          deployment: projectInstance,
        }),
      ).catch((err) => err);
    }
  }

  function checkForTeardownTemplate() {
    const teardownTemplate = projectInstance.teardownTemplate;
    if (teardownTemplate?.lastCommitId !== null) {
      setHasTeardownTemplate(true);
    }
  }

  useEffect(() => {
    checkForTeardownTemplate();
  }, [projectInstance.teardownTemplate]);

  useEffect(() => {}, [projectInstance.requestExtraStatus, projectInstance.latestCommitSha]);

  useEffect(() => {
    // Main update loop
    if (projectInstance.requestExtraStatus === 'empty') {
      dispatchDeploymentExtra();
    }
    dispatchDeploymentUpdates();

    const intervalId = setInterval(() => {
      dispatchDeploymentUpdates();
    }, 10 * 1000); // Poll every 10 seconds (10000 milliseconds)

    return () => {
      clearTimeout(intervalId);
    };
  }, []);

  const showValidationSection = useMemo(() => {
    if (
      validationStatus === 'MISSING_VALIDATE_CI_FILE' ||
      validationStatus === 'MISSING_PIPELINE' ||
      validationStatus === 'ANALYZING_STATUS'
    ) {
      return false;
    }
    return true;
  }, [validationStatus]);

  useEffect(() => {
    if (mainPipeline !== undefined) {
      const projectPath = extractProjectPath(mainPipeline.path);
      const pipelineId = parseGuid(mainPipeline.id);
      const job = getHomepageJob(mainPipeline);
      const jobId = job !== undefined ? parseGuid(job.id) : null;
      setSolutionHomepageUrl(`${dataopsliveBaseUrl}/${projectPath}/documentations/${pipelineId}?job=${jobId ?? ''}`);
      isSolutionHomepageAvailable(homepageStatus, jobId).catch(() => {});
    }
  }, [mainPipeline]);

  async function isSolutionHomepageAvailable(homepageStatus: HomepageStatus, jobId: string | null) {
    if (homepageStatus === 'Available') {
      return;
    }
    const url = `${dataopsliveBaseUrl}/api/v4/projects/${projectInstance.id}/jobs/${jobId ?? ''}`;
    const response = await axios.get(url, {
      headers: { authorization: `Bearer ${token}` },
    });

    if (response.data.status === 'success' || response.data.status === 'warning' || response.data.status === 'manual') {
      setHomepageStatus('Available');
    } else if (
      response.data.status === 'pending' ||
      response.data.status === 'running' ||
      response.data.status === 'created' ||
      response.data.status === 'scheduled'
    ) {
      setTimeout(() => {
        isSolutionHomepageAvailable(homepageStatus, jobId).catch(() => {});
      }, 10000);
    } else {
      setHomepageStatus('Failed');
    }
  }

  function getSnowflakeAccount(projectInstance: IDeployment): string | undefined {
    const account = projectInstance.ciVariables.find((variable: any) => variable.key === 'DATAOPS_SOLE_ACCOUNT');
    return account !== undefined ? account.value : undefined;
  }

  function getSnowflakeAccountURL(projectInstance: IDeployment): string {
    const account = getSnowflakeAccount(projectInstance);
    if (account !== undefined) {
      return `https://${account}.snowflakecomputing.com/`;
    } else {
      return 'https://www.snowflake.com/login/';
    }
  }

  function openLaunchHomepageModal() {
    setLaunchHomepageModalOpen(true);
  }

  return (
    <>
      <DeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        projectId={projectInstance.guid}
        projectInstanceName={projectInstance.name}
        snowflakeAccount={getSnowflakeAccount(projectInstance) ?? ''}
        refreshProjects={dispatchDeploymentUpdates}
      />
      <ShareModal
        open={shareModalOpen}
        closeModal={() => {
          setShareModalOpen(false);
        }}
        deployment={projectInstance}
        solution={solution}
      />

      <li key={projectInstance.name} className="flex items-center justify-between py-3 text-sm">
        <HomepageLaunchModal
          salesForceId={useCaseId}
          open={launchHomepageModalOpen}
          setOpen={setLaunchHomepageModalOpen}
          mainPipelineStatus={mainPipelineStatus}
          projectPipelinesUrl={projectPipelinesUrl}
          solutionHomepageUrl={solutionHomepageUrl}
          cancelButtonRef={cancelButtonRef}
          username={username}
          projectDeployment={projectInstance}
          dataopsProjectID={templateProjectId}
          refreshProjects={dispatchDeploymentUpdates}
        />
        <dl
          className={classNames(
            'relative grow w-full grid grid-cols-1 divide-y divide-gray-200 bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0 overflow-visible border-t-2 border-t-slate-100',
          )}
        >
          <PipelineProgressBar deployment={projectInstance} />

          <div>
            <ProjectInstanceCardDetails
              customerName={customerName}
              customerLogoUrl={customerLogoUrl}
              projectInstance={projectInstance}
            />
            {holEnabled && <HolParticipantDetails projectInstance={projectInstance} />}
          </div>

          <div className="px-4 py-5 sm:p-6">
            <div className="divide-y divide-gray-200">
              <div>
                <dt className="text-sm font-medium text-gray-500 flex flex-row justify-between">
                  <div>Deployment Status</div>
                </dt>
                <dd className="mt-1 mb-2 flex items-baseline justify-between md:block lg:flex">
                  {mainPipeline === undefined || projectInstance.requestExtraStatus === 'empty' ? (
                    <div className="w-full space-y-1">
                      <div className="w-full h-6 bg-gray-100 rounded-lg bg-shine"></div>
                      <div className="w-full h-6 bg-gray-100 rounded-lg bg-shine"></div>
                    </div>
                  ) : (
                    <ProjectStatus projectInstance={projectInstance} refreshProject={dispatchDeploymentUpdates} />
                  )}
                </dd>
              </div>
              <div>
                <dt className="mt-2 text-sm font-medium text-gray-500 flex flex-row content-center items-center">
                  <div>Validation Status</div>
                </dt>
                <dd className="w-full mt-1 py-2 md:block lg:flex">
                  {!showValidationSection || projectInstance.requestExtraStatus === 'empty' ? (
                    <div className="w-full space-y-1">
                      <div className="w-full h-6 bg-gray-100 rounded-lg bg-shine"></div>
                      <div className="w-full h-6 bg-gray-100 rounded-lg bg-shine"></div>
                    </div>
                  ) : (
                    <ValidationStatus projectInstance={projectInstance} refreshProject={dispatchDeploymentUpdates} />
                  )}
                </dd>
              </div>
            </div>
          </div>

          <div className="w-full px-4 py-5 sm:p-6 ">
            <dt className="w-full text-sm font-medium text-gray-500 flex flex-row content-center items-center">
              Actions
            </dt>
            <dd className="mt-1 w-full grid grid-cols-1 gap-2">
              <div className="flex flex-row">
                {(mainPipelineStatus === 'SETUP_IN_PROGRESS' ||
                  mainPipelineStatus === 'SETUP_FAILED' ||
                  mainPipelineStatus === 'SETUP_SUCCESS') &&
                  homepageStatus === 'Available' &&
                  solutionHomepageUrl !== undefined && (
                    <Button
                      size="medium"
                      onClick={() => {
                        openLaunchHomepageModal();
                      }}
                      className="w-full"
                    >
                      <HomeIcon className="w-6 h-5 " />
                      Solution homepage
                      <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                    </Button>
                  )}
                {homepageStatus !== 'Available' && (
                  <div
                    className="grow group flex relative"
                    ref={containerRef}
                    onMouseLeave={() => {
                      hideTooltip();
                    }}
                    onMouseMove={(event) => {
                      const eventSvgCoords = localPoint(event);
                      showTooltip({
                        tooltipData: {
                          description:
                            homepageStatus === 'Pending'
                              ? 'Solution homepage not yet created, please check back in a few minutes (this button will refresh automatically)'
                              : 'Failed to build solution homepage. Please check the pipeline logs for more information.',
                        },
                        tooltipTop: eventSvgCoords?.y,
                        tooltipLeft: eventSvgCoords?.x,
                      });
                    }}
                  >
                    <Button size="medium" intent="secondaryAction" className="w-full cursor-default">
                      {homepageStatus === 'Pending' ? (
                        <Spinner size="medium" className="mr-1" />
                      ) : (
                        <XCircleIcon className="w-3 h-3 ml-1" />
                      )}
                      Solution homepage
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <Button url={getSnowflakeAccountURL(projectInstance)} intent="light" className="w-full">
                  <CircleStackIcon className="w-5 h-5 mr-1" />
                  Snowsight UI
                  <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setShareModalOpen(true);
                  }}
                  intent="light"
                  className="w-full"
                  disabled={
                    projectInstance.requestExtraStatus === 'empty' || projectInstance.latestCommitSha === undefined
                  }
                >
                  {projectInstance.latestCommitSha === undefined ? (
                    <Spinner size="medium" className="mr-1" />
                  ) : (
                    <ShareIcon className="w-5 h-5 mr-1" />
                  )}
                  Share
                </Button>
              </div>
              <div>
                <Button url={projectPipelinesUrl} intent="light" className="w-full">
                  <RocketLaunchIcon className="w-5 h-5 mr-1" />
                  DataOps.live pipeline
                  <ArrowTopRightOnSquareIcon className="w-3 h-3 ml-1" />
                </Button>
              </div>
              {hasTeardownTemplate && (
                <Button
                  intent="danger"
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                  className="w-full"
                  disabled={isReadOnlyUser}
                >
                  <TrashIcon className="w-5 h-5 mr-1" />
                  Delete solution
                </Button>
              )}
            </dd>
          </div>
        </dl>
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="text-sm">{tooltipData.description}</div>
          </TooltipInPortal>
        )}
      </li>
    </>
  );
}
