import React from 'react';
import { classNames } from '../../utilities/styleUtils';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  optional?: boolean;
  icon?: React.ElementType;
}

export function Input({ label = undefined, optional = false, icon = undefined, ...props }: InputProps) {
  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <label htmlFor={props.id ?? 'input'} className="mb-2 block text-sm font-medium leading-6 text-gray-900">
            {label}
          </label>
        )}
        {optional && (
          <span id={`${props.id ?? 'input'}-optional`} className="mb-2 text-sm leading-6 text-gray-500">
            Optional
          </span>
        )}
      </div>
      <div className="relative">
        <input
          className={classNames(
            'h-[33px] w-full border border-gray-200 bg-transparent px-4 text-gray-900 placeholder:text-gray-500 ring-0 focus:ring-0 sm:text-sm rounded-md outline-none outline-offset-0 focus:outline focus:outline-dataops-primary-light-blue/20 focus:outline-4 transition-all ease-in-out',
            icon ? 'pl-11' : '',
          )}
          {...props} // Spread the rest of the props to the input element
        />
        {icon &&
          React.createElement(icon, { className: 'pointer-events-none absolute left-3 top-2 h-5 w-5 text-gray-500' })}
      </div>
    </div>
  );
}
