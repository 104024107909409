import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface Props {
  title: string;
  children: React.ReactNode;
  intent: 'success' | 'warning' | 'error';
}

export default function Alert({ title, children: desc, intent }: Props) {
  const intentToColor = {
    success: 'green',
    warning: 'yellow',
    error: 'red',
  };
  const intentToIcon = {
    success: CheckCircleIcon,
    warning: ExclamationCircleIcon,
    error: ExclamationCircleIcon,
  };
  const Icon = intentToIcon[intent];
  return (
    <div className={`rounded-md bg-${intentToColor[intent]}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon aria-hidden="true" className={`h-5 w-5 text-${intentToColor[intent]}-700`} />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${intentToColor[intent]}-700`}>{title}</h3>
          <div className={`mt-2 text-sm text-${intentToColor[intent]}-700`}>
            <div>{desc}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
